.root {
  padding: 0 calc(var(--mui-spacing) * 3);
  text-align: left;
  width: 100%;
}

.title,
.paragraph {
  margin-bottom: calc(var(--mui-spacing) * 2) !important;
}

.spacer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 150vw;
  margin-left: calc(-1 * (var(--mui-spacing) * 26));
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: calc(var(--mui-spacing) * 8);
}

.logoContainer {
  display: flex;
  width: fit-content;
  padding: calc((var(--mui-spacing) * 3)) 0;
  border-radius: calc((var(--mui-spacing) * 1.5));
}

.logoContainer > svg {
  height: 26px;
  width: 141px;
}

.supportLink a {
  color: var(--design-color-secondary-dark);
  font-weight: 600;
}

@media (--mui-breakpoint-sm-up) {
  .root {
    padding: 0 15%;
  }
}
