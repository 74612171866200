html {
  scroll-behavior: smooth;
}

#app {
  display: flex;
  flex-direction: column;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/* For unstyled links */
a {
  color: var(--mui-palette-secondary-main);
  font-weight: var(--mui-typography-linkMedium-fontWeight);
}

/* For dangerouslySetInnerHTML translation labels */
h1 {
  font-family: var(--mui-typography-h1-fontFamily);
  font-weight: var(--mui-typography-h1-fontWeight);
  font-size: var(--mui-typography-h1-fontSize);
  line-height: var(--mui-typography-h1-lineHeight);
}

h2 {
  font-family: var(--mui-typography-h2-fontFamily);
  font-weight: var(--mui-typography-h2-fontWeight);
  font-size: var(--mui-typography-h2-fontSize);
  line-height: var(--mui-typography-h2-lineHeight);
}

h3 {
  font-family: var(--mui-typography-h3-fontFamily);
  font-weight: var(--mui-typography-h3-fontWeight);
  font-size: var(--mui-typography-h3-fontSize);
  line-height: var(--mui-typography-h3-lineHeight);
}

h4 {
  font-family: var(--mui-typography-h4-fontFamily);
  font-weight: var(--mui-typography-h4-fontWeight);
  font-size: var(--mui-typography-h4-fontSize);
  line-height: var(--mui-typography-h4-lineHeight);
}

h5 {
  font-family: var(--mui-typography-h5-fontFamily);
  font-weight: var(--mui-typography-h5-fontWeight);
  font-size: var(--mui-typography-h5-fontSize);
  line-height: var(--mui-typography-h5-lineHeight);
}

/* End for dangerouslySetInnerHTML translation labels */

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
