/**
 * this file is auto generated. please run `npx nx run kamernet:generate-theme-styles` to update this content.
 */

:root {
  --design-bodyFontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --design-headerFontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --design-color-common-black: #000;
  --design-color-common-white: #fff;
  --design-color-common-transparentGray: rgba(255, 255, 255, 0.2);
  --design-color-text-primary: #00163f;
  --design-color-text-secondary: #989898;
  --design-color-primary-contrastText: #fff;
  --design-color-primary-dark: #038f5d;
  --design-color-primary-main: #00a56a;
  --design-color-primary-light: #06b475;
  --design-color-primary-accent: #e9f4f3;
  --design-color-secondary-contrastText: #fff;
  --design-color-secondary-dark: #020f28;
  --design-color-secondary-main: #00163f;
  --design-color-secondary-light: #213862;
  --design-color-secondary-accent: #455a71;
  --design-color-tertiary-contrastText: #fff;
  --design-color-tertiary-dark: #5e4006;
  --design-color-tertiary-main: #f8c051;
  --design-color-tertiary-light: #ffd873;
  --design-color-tertiary-accent: #fcf4dd;
  --design-color-success-contrastText: #fff;
  --design-color-success-dark: #005f1b;
  --design-color-success-main: #06721e;
  --design-color-success-light: #0a9e33;
  --design-color-success-accent: #e3fce3;
  --design-color-danger-contrastText: #fff;
  --design-color-danger-dark: #ac0400;
  --design-color-danger-main: #cb302c;
  --design-color-danger-light: #e94944;
  --design-color-danger-accent: #ffe2e1;
  --design-color-warning-contrastText: #fff;
  --design-color-warning-dark: #c75300;
  --design-color-warning-main: #e86108;
  --design-color-warning-light: #fc7d21;
  --design-color-warning-accent: #ffe8d2;
  --design-color-mono-contrastText: #fff;
  --design-color-mono-dark: #cedbe3;
  --design-color-mono-main: #e2e9ee;
  --design-color-mono-light: #f2f7fb;
  --design-color-mono-accent: #fafdff;
  --design-color-neutral-contrastText: #fff;
  --design-color-neutral-main: #000;
  --design-color-neutral-overlay: rgba(2, 15, 40, 0.5);
  --design-color-neutral-gray: #99a3ac;
  --design-color-neutral-light: #fff;
  --design-breakpoint-xs: 0;
  --design-breakpoint-sm: 600;
  --design-breakpoint-md: 1024;
  --design-breakpoint-lg: 1360;
  --design-breakpoint-xl: 1680;
  --design-borderRadius-xs: 4px;
  --design-borderRadius-sm: 8px;
  --design-borderRadius-md: 16px;
  --design-borderRadius-lg: 24px;
  --design-borderRadius-xl: 32px;
  --design-fontSize-sm: 14px;
  --design-fontSize-md: 24px;
  --design-fontSize-lg: 40px;
}
